
import { ref, defineComponent, toRefs } from 'vue';

import handlerFileFieldApi from '@/lib/formFactory/fileField/handler';

export default defineComponent({
  name: 'form-input-file',
  emits: ['update'],
  props: {
    label: { type: String },
    labelActive: { type: Boolean },
    disabled: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const propsRefs = toRefs(props);
    const root = ref(null);
    const uniqueId = Math.random();

    const handler = handlerFileFieldApi({
      input: emit as (eventName: string, eventData: unknown) => void,
      value: propsRefs.label,
    });

    return {
      value: handler.value,
      root,
      handler,
      uniqueId,
    };
  },
  components: {},
});
