import { ref } from 'vue';
import { Ref } from '@vue/reactivity/dist/reactivity';

interface EventTargetValue {
  target: {
    files: { name: string }[];
  };
}

export interface FormFileInputValue {
  name: string;
}

export interface FormInputHandlerParams {
  input: (eventName: string, eventData?: unknown) => void;
  value: Ref<string | undefined>;
}

export interface FormInputHandlerResult {
  update: (params: EventTargetValue) => void;
  value: Ref<string>;
}

export default function formInputHandler(params: FormInputHandlerParams): FormInputHandlerResult {
  const { input } = params;
  const value = ref('');

  const update = ({ target: { files } }: EventTargetValue) => {
    input('update', files[0]);
    value.value = files[0].name;
  };

  return {
    update,
    value,
  };
}
