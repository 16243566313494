
import { Component, Vue } from '@/lib/decorator';
import SettingsModule from '@/store/settings';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TechnicalModule, { HrmSetting } from '@/store/settings/technical';

import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormInputFile from '@/components/form/Input/file.vue';
import ModalBlock from '@/components/Modal.vue';
import FormInput from '@/components/form/Input/index.vue';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    FormInput,
    TabsNav,
    AppFieldset,
    AppRow,
    AppCol,
    FormButton,
    FormInputFile,
    ModalBlock,
  },
})
export default class SettingsTechnicalMain extends Vue {
  employeesFile: Record<string, string> = { name: '' };
  isUploading = false;
  uploadedFile = '';
  resultReceived = false;
  duplicated = 0;
  submitted = 0;
  imported = 0;
  failed = 0;

  editHrmSettingsModalShown = false;

  get hrmSettingsList(): HrmSetting[] {
    return TechnicalModule.settingsList;
  }

  get currentEditingSetting(): {} | HrmSetting {
    return TechnicalModule.currentEditingSetting;
  }

  get tabsNav(): TabsNavInterface {
    return SettingsModule.tabsNav;
  }

  get currentTab(): string {
    return SettingsModule.allTabs.technical.id;
  }

  get isAlreadyUploaded(): boolean {
    return !!this.uploadedFile && this.uploadedFile === this.employeesFile.name;
  }

  employeesFileUpdated(value: Record<string, string>): void {
    this.employeesFile = value;
  }

  mounted(): void {
    SettingsModule.initTabsItems();
    TechnicalModule.getHrmSettingsList();
  }

  async editHrmSetting(key: string): Promise<void> {
    await TechnicalModule.getHrmEditingSetting(key);
    this.editHrmSettingsModalShown = true;
  }

  async saveHrmSetting(): Promise<void> {
    await TechnicalModule.saveHrmSetting();
    await TechnicalModule.getHrmSettingsList();
    this.editHrmSettingsModalShown = false;
  }

  async uploadFile(): Promise<void> {
    this.isUploading = true;

    try {
      const result = await TechnicalModule.uploadEmployeesFile(this.employeesFile);

      this.resultReceived = true;
      this.duplicated = result.employeesFile.duplicated;
      this.submitted = result.employeesFile.submitted;
      this.imported = result.employeesFile.imported;
      this.failed = result.employeesFile.failed;

      const failReports = [result.failedImportsCsvPath, result.duplicatedImportsCsvPath].filter(Boolean);

      failReports.forEach(this.downloadFailedImports);

      if (!result.failedImportsCsvPath) {
        this.uploadedFile = this.employeesFile.name;
      }
    } finally {
      this.isUploading = false;
    }
  }

  downloadFailedImports(path: string): void {
    const downloadLink = document.createElement('a');
    downloadLink.href = path;
    downloadLink.target = '_blank';
    downloadLink.style.display = 'none';
    document.body.append(downloadLink);
    downloadLink.click();
    setTimeout(() => {
      downloadLink.remove();
    });
  }
}
