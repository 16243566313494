
import './fieldsetLegend.scss';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppFieldsetLegend',
  props: {
    buttonKey: String,
    align: String,
    info: String,
  },
});
