import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]
const _hoisted_6 = ["fill"]
const _hoisted_7 = ["stroke"]
const _hoisted_8 = {
  key: 1,
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_9 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.isHidden)
      ? (_openBlock(), _createElementBlock("svg", _hoisted_1, [
          _createElementVNode("path", {
            d: "M14.3351 6.64977C13.0349 5.83899 11.5323 5.41157 9.99998 5.41667C9.32178 5.41441 8.64938 5.49689 7.99778 5.65983L8.79073 6.45026C9.17035 6.32047 9.57714 6.25 9.99998 6.25C12.0666 6.25 13.75 7.93333 13.75 10C13.75 10.4232 13.6794 10.8303 13.5494 11.2102L15.1301 12.7871C15.13 12.7872 15.1301 12.7871 15.1301 12.7871C16.0479 12.0477 16.8096 11.1036 17.35 10C16.6803 8.6218 15.6353 7.46056 14.3351 6.64977Z",
            fill: _ctx.color
          }, null, 8, _hoisted_2),
          _createElementVNode("path", {
            d: "M11.4731 8.52686C11.149 8.20277 10.7291 7.99648 10.28 7.93556L12.064 9.71677C12.0025 9.26885 11.7964 8.85019 11.4731 8.52686Z",
            fill: _ctx.color
          }, null, 8, _hoisted_3),
          _createElementVNode("path", {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M9.99998 5.41667C11.5323 5.41157 13.0349 5.83899 14.3351 6.64977C15.6353 7.46056 16.6803 8.6218 17.35 10C16.8096 11.1036 16.0479 12.0477 15.1301 12.7871L15.193 12.8501L16.3042 13.9635C17.5625 12.912 18.5551 11.552 19.1666 10C17.725 6.34167 14.1666 3.75 9.99998 3.75C8.82974 3.75 7.70749 3.95443 6.66665 4.32951L7.99778 5.65983C8.64938 5.49689 9.32178 5.41441 9.99998 5.41667ZM14.5833 15.1185L13.4245 13.842C12.3761 14.3223 11.2144 14.5833 9.99998 14.5833C6.83331 14.5833 4.02498 12.8083 2.64998 10C3.31966 8.6218 4.36467 7.46056 5.66488 6.64977C5.76081 6.58995 5.85785 6.53221 5.95592 6.47658L4.76336 5.25908C2.99726 6.37231 1.60894 8.03178 0.833313 10C2.27498 13.6583 5.83331 16.25 9.99998 16.25C11.6553 16.25 13.2146 15.841 14.5833 15.1185Z",
            fill: _ctx.color
          }, null, 8, _hoisted_4),
          _createElementVNode("path", {
            d: "M8.52684 11.4731C8.91754 11.8638 9.44744 12.0833 9.99998 12.0833C10.4533 12.0833 10.8915 11.9356 11.25 11.6667L8.3333 8.75C8.06442 9.1085 7.91665 9.54663 7.91665 10C7.91665 10.5525 8.13614 11.0824 8.52684 11.4731Z",
            fill: _ctx.color
          }, null, 8, _hoisted_5),
          _createElementVNode("path", {
            d: "M2.64998 10C4.02498 12.8083 6.83331 14.5833 9.99998 14.5833C11.2144 14.5833 12.3761 14.3223 13.4245 13.842L12.5 12.792C11.8359 13.3873 10.9592 13.75 9.99998 13.75C7.93331 13.75 6.24998 12.0667 6.24998 10C6.24998 9.07691 6.58581 8.23029 7.14163 7.57599L5.95592 6.47658C5.85785 6.53221 5.76081 6.58995 5.66488 6.64977C4.36467 7.46056 3.31966 8.6218 2.64998 10Z",
            fill: _ctx.color
          }, null, 8, _hoisted_6),
          _createElementVNode("line", {
            x1: "4.3309",
            y1: "4.58325",
            x2: "15.4167",
            y2: "15.669",
            stroke: _ctx.color,
            "stroke-width": "2",
            "stroke-linecap": "round"
          }, null, 8, _hoisted_7)
        ]))
      : (_openBlock(), _createElementBlock("svg", _hoisted_8, [
          _createElementVNode("path", {
            d: "M10 5.41667C11.5323 5.41157 13.0349 5.83899 14.3351 6.64977C15.6353 7.46056 16.6803 8.6218 17.35 10C15.975 12.8083 13.1667 14.5833 10 14.5833C6.83334 14.5833 4.02501 12.8083 2.65001 10C3.31969 8.6218 4.3647 7.46056 5.66491 6.64977C6.96511 5.83899 8.46773 5.41157 10 5.41667V5.41667ZM10 3.75C5.83334 3.75 2.27501 6.34167 0.833344 10C2.27501 13.6583 5.83334 16.25 10 16.25C14.1667 16.25 17.725 13.6583 19.1667 10C17.725 6.34167 14.1667 3.75 10 3.75ZM10 7.91667C10.5525 7.91667 11.0824 8.13616 11.4731 8.52686C11.8638 8.91756 12.0833 9.44747 12.0833 10C12.0833 10.5525 11.8638 11.0824 11.4731 11.4731C11.0824 11.8638 10.5525 12.0833 10 12.0833C9.44747 12.0833 8.91757 11.8638 8.52687 11.4731C8.13617 11.0824 7.91668 10.5525 7.91668 10C7.91668 9.44747 8.13617 8.91756 8.52687 8.52686C8.91757 8.13616 9.44747 7.91667 10 7.91667V7.91667ZM10 6.25C7.93334 6.25 6.25001 7.93333 6.25001 10C6.25001 12.0667 7.93334 13.75 10 13.75C12.0667 13.75 13.75 12.0667 13.75 10C13.75 7.93333 12.0667 6.25 10 6.25Z",
            fill: _ctx.color
          }, null, 8, _hoisted_9)
        ]))
  ]))
}