
import './fieldset.scss';
import { defineComponent } from 'vue';
import AppFieldsetLegend from '@/components/ui/Fieldset/FieldsetLegend.vue';

export default defineComponent({
  name: 'AppFieldset',
  components: { AppFieldsetLegend },
  props: {
    withLegend: {
      type: Boolean,
      default: true,
    },
  },
});
