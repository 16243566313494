import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: 'select-import-file-' + _ctx.uniqueId,
      class: _normalizeClass(['cp-input-file__label cp-input-file__label_width-full', _ctx.disabled ? 'cp-input-file__label_disabled' : ''])
    }, _toDisplayString(_ctx.value), 11, _hoisted_1),
    _createElementVNode("input", {
      id: 'select-import-file-' + _ctx.uniqueId,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handler.update && _ctx.handler.update(...args))),
      type: "file",
      accept: "text/csv",
      class: "cp-input-file__input",
      disabled: _ctx.disabled
    }, null, 40, _hoisted_2)
  ]))
}