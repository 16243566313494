import API from '@/config/api-routers';
import { makeFormRequest, makeGetRequest } from '@/api/requests';
import { FormData, FormResponse } from '@/interfaces/shared';
import { HrmSetting } from '@/store/settings/technical';

/**
 * get hrm settings list
 */
export const getHrmSettingsList = async (): Promise<HrmSetting[]> => {
  const url = API.settings.getList;
  const result = await makeGetRequest(url);

  return result.data.result.table.rows;
};

/**
 * get hrm single setting
 *
 * @param key
 */
export const getHrmSingleSetting = async (key: string): Promise<HrmSetting> => {
  const url = API.settings.getSingleSetting.replace('$1', key);
  const result = await makeGetRequest(url);

  return result.data.result.settings;
};

/**
 * update hrm single setting
 *
 * @param data
 */
export const updateHrmSingleSetting = async (data: FormData): Promise<FormResponse> => {
  const url = API.settings.updateSingleSetting;
  const result = await makeFormRequest(url, data);

  return result.data;
};
