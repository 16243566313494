import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import { CatchFormResponse } from '@/interfaces/shared';
import { strings } from '@/lib/stringConst';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { importBulkEmployees } from '@/api/employees';
import { getHrmSettingsList, getHrmSingleSetting, updateHrmSingleSetting } from '@/api/hrmSettings';
import ResponseHandlerModule from '@/store/modules/responseHandler';

export const MODULE_NAME = 'technicals';

export interface HrmSetting {
  key: string;
  name: string;
  value?: string;
  description?: string;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class TechnicalModule extends PageBaseModule {
  settingsList: HrmSetting[] = [];
  currentEditingSetting: HrmSetting | {} = {};

  constructor(module: TechnicalModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Технические');
  }

  @Mutation
  SET_SETTINGS_LIST(list: HrmSetting[]) {
    this.settingsList = list;
  }

  @Mutation
  SET_SINGLE_SETTINGS(item: HrmSetting) {
    this.currentEditingSetting = item;
  }

  @Action({ rawError: true })
  async uploadEmployeesFile(employeesFile: Record<string, string>) {
    try {
      return await importBulkEmployees({
        employeesFile: employeesFile,
      });
    } catch (error) {
      const responseError = (error as CatchFormResponse)?.response.data;
      let errorMessage = strings.UNKNOWN_ERROR;

      if (responseError) {
        errorMessage = responseError.message || Object.values(responseError.errors?.fields).join('; ') || errorMessage;
      }

      ResponseHandlerModule.showNotify({ message: errorMessage, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getHrmSettingsList() {
    try {
      const result = await getHrmSettingsList();
      this.context.commit(
        'SET_SETTINGS_LIST',
        result.map((item: HrmSetting) => ({
          key: item.key,
          name: item.name,
          value: item.value,
        }))
      );
    } catch (error) {
      this.context.commit('SET_SETTINGS_LIST', []);
    }
  }

  @Action({ rawError: true })
  async getHrmEditingSetting(key: string) {
    try {
      const result = await getHrmSingleSetting(key);
      this.context.commit('SET_SINGLE_SETTINGS', result);
    } catch (error) {
      this.context.commit('SET_SINGLE_SETTINGS', {});
    }
  }

  @Action({ rawError: true })
  async saveHrmSetting() {
    try {
      await updateHrmSingleSetting({
        key: (this.currentEditingSetting as HrmSetting).key,
        value: (this.currentEditingSetting as HrmSetting).value as string,
        name: (this.currentEditingSetting as HrmSetting).name,
        description: (this.currentEditingSetting as HrmSetting).description as string,
      });
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.data.message, type: 'fail' });
    }
  }
}

export default getModule(TechnicalModule);
