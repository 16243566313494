import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-fieldset" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_fieldset_legend = _resolveComponent("app-fieldset-legend")!

  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    (_ctx.withLegend)
      ? _renderSlot(_ctx.$slots, "legend", { key: 0 }, () => [
          _createVNode(_component_app_fieldset_legend, { class: "app-fieldset__title" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title")
            ]),
            _: 3
          })
        ])
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}