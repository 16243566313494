import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["accesskey", "align", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("legend", {
    class: "app-fieldset-legend",
    accesskey: _ctx.buttonKey,
    align: _ctx.align,
    title: _ctx.info
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}