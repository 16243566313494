import { watch } from 'vue';
import { Ref } from '@vue/reactivity';

export interface FormAutoFillParams {
  autofill: Ref<boolean>;
  isLabelActive: Ref<boolean>;
}

export default function formAutoFill(params: FormAutoFillParams): void {
  const autofill: any = params.autofill;

  watch(autofill, () => {
    if (params.autofill) {
      params.isLabelActive.value = true;
    }
  });
}
