import { clearPhone } from '@/lib/Utils';
import { watch, onMounted } from 'vue';
import moment from 'moment';
import { Ref } from '@vue/reactivity';

export interface FormInputModifyParams {
  input: (eventName: string, eventData: unknown) => void;
  type: string;
  value: Ref<string | number>;
  validateOff: boolean;
}

export default function formInputModify(params: FormInputModifyParams): void {
  const value = params.value;

  //phone
  const modifyPhone = () => {
    let tmpValue = value.value;
    if (typeof tmpValue !== 'string') {
      tmpValue = tmpValue.toString();
    }

    params.input('update:modifyValue', clearPhone(tmpValue));
  };

  const modifyPhoneMount = () => {
    let tmpValue = value.value;
    if (typeof tmpValue !== 'string') {
      tmpValue = tmpValue.toString();
    }

    if (!tmpValue.length) {
      return;
    }

    params.input('update:modelValue', `+7${tmpValue.slice(1)}`);
  };

  //birthday
  const modifyBirthday = () => {
    let tmpValue = value.value;
    if (typeof tmpValue !== 'string') {
      tmpValue = tmpValue.toString();
    }

    const newDate: any = tmpValue.split('.');

    const newDateString = newDate[1] + '/' + newDate[0] + '/' + newDate[2];

    const modifyValue = moment(new Date(newDateString)).format('YYYY-MM-DDT00:00:00');

    params.input('update:modifyValue', modifyValue);
  };

  const modifyBirthdayMount = () => {
    let tmpValue = value.value;
    if (typeof tmpValue !== 'string') {
      tmpValue = tmpValue.toString();
    }

    if (!tmpValue.length) {
      return;
    }

    params.input('update:modelValue', moment(tmpValue).format('DD.MM.YYYY'));
  };

  //date
  const modifyDate = () => {
    let tmpValue = value.value;
    if (typeof tmpValue !== 'string') {
      tmpValue = tmpValue.toString();
    }

    const newDate = tmpValue.split('.');
    const newDateString = newDate[1] + '/' + newDate[0] + '/' + newDate[2];

    const modifyValue = moment(new Date(newDateString)).format('YYYY-MM-DD');

    params.input('update:modifyValue', modifyValue);
  };

  watch(params.value, () => {
    if (params.validateOff) return;

    if (params.type === 'phone') modifyPhone();

    if (params.type === 'birthday') modifyBirthday();

    if (params.type === 'date') modifyDate();
  });

  onMounted(() => {
    if (params.validateOff) return;

    if (params.type === 'phone') modifyPhoneMount();

    if (params.type === 'birthday') modifyBirthdayMount();

    if (params.type === 'date') modifyBirthdayMount();
  });
}
